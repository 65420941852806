import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import DeliveryCalculator from './views/DeliveryCalculator';
import ComputeCreateOrder from './views/ComputeCreateOrder';
import RecapBeforePayment from './views/RecapBeforePayment';
import Checkout from './views/Checkout';
import NoMatch from './views/NoMatch';
import OrderFollow from './views/OrderFollow';
import HomePage from './views/HomePage';

import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Router>
          <Switch>
            <Route exact path="/compute">
              <ComputeCreateOrder />
            </Route>
            <Route exact path="/recap/:orderID">
              <RecapBeforePayment />
            </Route>
            <Route exact path="/checkout/:orderID">
              <Checkout />
            </Route>
            <Route exact path="/order/:orderID">
              <OrderFollow />
            </Route>
            <Route exact path="/calculator/admin/:adminID">
              <DeliveryCalculator />
            </Route>

            <Route exact path="/">
              <HomePage />
            </Route>

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </ChakraProvider>
    </div>
  );
}

export default App;
