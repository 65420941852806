import React from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import {
  ButtonWrapper,
  AddressIcon,
  ArrowDownIcon,
  ArrowDownWrapper,
  InputsWrapper,
} from '../../styled/Calculator';
import { COLORS } from '../../../constants';

const CalculatorForm = props => {
  const {
    onSubmit,
    onChangeShopAddress,
    onChangeClientAddress,
    canSubmit,
    error,
    addresses,
  } = props;

  return (
    <>
      <InputsWrapper>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            padding={'4px'}
            children={<AddressIcon />}
          />
          <Input
            type="text"
            placeholder="Adresse de l'établissement partenaire"
            onChange={e => onChangeShopAddress(e.target.value)}
            value={addresses.shopAddress || ''}
          />
        </InputGroup>

        <ArrowDownWrapper>
          <ArrowDownIcon />
        </ArrowDownWrapper>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            padding={'4px'}
            children={<AddressIcon />}
          />
          <Input
            type="text"
            placeholder="Adresse de livraison"
            onChange={e => onChangeClientAddress(e.target.value)}
            value={addresses.clientAddress || ''}
          />
        </InputGroup>
      </InputsWrapper>

      {error ? <Text color={COLORS.red}>{error.message}</Text> : null}

      <ButtonWrapper>
        <Button
          size="lg"
          colorScheme="pink"
          variant="solid"
          minWidth="50%"
          onClick={onSubmit}
          isDisabled={!canSubmit}
        >
          Calculer
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default CalculatorForm;
