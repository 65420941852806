import styled from 'styled-components';

import { mediaQueries } from '../../helpers';
import { SPACING, COLORS, BOX_SHADOW } from '../../constants';

const RecapWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACING[200]};
`;

const ResumeBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 300px;
  width: 75%;
  padding: ${SPACING[100]};
  border: 2px solid ${COLORS.primary};
  box-shadow: ${BOX_SHADOW.lg};

  ${mediaQueries.tablet(`
    flex-direction: column;
  `)}
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACING[500]};

  ${mediaQueries.mobile(`
    padding: ${SPACING[200]};
  `)}
`;

const IconWrapper = styled.div`
  padding: ${SPACING[100]};
  transform: rotate(90deg);
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const IdsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.paddingTop || SPACING[100]} 0 ${SPACING[300]} 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[400]} 0 0 0;
`;

export {
  RecapWrapper,
  ResumeBlock,
  DetailsWrapper,
  IconWrapper,
  TextWrapper,
  IdsWrapper,
  ButtonWrapper,
};
