import React from 'react';
import styled from 'styled-components';
import { Text } from '@chakra-ui/react';

import { COLORS, SPACING, BOX_SHADOW } from '../../../constants';
import { mediaQueries } from '../../../helpers';

import { ArrowDownIcon } from '../../styled/Calculator';

const RecapWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACING[200]};
`;

const ResumeBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 300px;
  width: 75%;
  padding: ${SPACING[100]};
  border: 2px solid ${COLORS.primary};
  box-shadow: ${BOX_SHADOW.lg};
  ${mediaQueries.tablet(`
    flex-direction: column;
  `)}
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACING[500]};
  padding: ${SPACING[200]};
`;

const IconWrapper = styled.div`
  padding: ${SPACING[100]};
  transform: rotate(90deg);
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const RecapView = props => {
  const { deliveryDetails } = props;

  return (
    <RecapWrapper>
      <ResumeBlock>
        <Text fontSize="lg" color={COLORS.gray} paddingX={SPACING[300]}>
          {deliveryDetails.duration}
        </Text>
        <Text fontSize="2xl" color={COLORS.primary} paddingX={SPACING[300]}>
          {deliveryDetails.cost}€
        </Text>
        <Text fontSize="lg" color={COLORS.gray} paddingX={SPACING[300]}>
          {deliveryDetails.distance}
        </Text>
      </ResumeBlock>

      <DetailsWrapper>
        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Boutique:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {deliveryDetails.addresses.shopAddress}
          </Text>
        </TextWrapper>

        <IconWrapper>
          <ArrowDownIcon />
        </IconWrapper>

        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Client:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {deliveryDetails.addresses.clientAddress}
          </Text>
        </TextWrapper>
      </DetailsWrapper>
    </RecapWrapper>
  );
};

export default RecapView;
