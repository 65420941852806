import React from 'react';
import styled from 'styled-components';
import { Heading } from '@chakra-ui/react';

import Logo from './Logo';

import { BOX_SHADOW, COLORS, SPACING } from '../constants';

const HeaderWrapper = styled.div`
  width: 100%;
  height: 220px;
  top: 0;
  margin: 0;
  padding: ${SPACING[200]};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid lightgray;
  box-shadow: ${BOX_SHADOW.xl};
  background-color: ${COLORS.primary};
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = props => {
  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Logo />
        <Heading
          as="h1"
          size="lg"
          color={COLORS.white}
          marginLeft={SPACING[200]}
        >
          Illi'Corsica Paiement
        </Heading>
      </ContentWrapper>
    </HeaderWrapper>
  );
};

export default Header;
