import React from 'react';
import { Text } from '@chakra-ui/react';

import { COLORS, SPACING } from '../../../constants';
import {
  RecapWrapper,
  ResumeBlock,
  DetailsWrapper,
  IconWrapper,
  TextWrapper,
  IdsWrapper,
} from '../../styled/Recap';

import { ArrowDownIcon } from '../../styled/Calculator';

const FollowRecap = props => {
  const { order } = props;

  return (
    <RecapWrapper>
      <IdsWrapper>
        <Text fontSize="md">
          <span style={{ color: COLORS.gray }}>Payé : </span> {order.cost}€
        </Text>
      </IdsWrapper>

      <ResumeBlock>
        <Text fontSize="2xl" color={COLORS.primary} paddingX={SPACING[300]}>
          <span style={{ color: COLORS.gray }}>Status : </span> {order.status}{' '}
        </Text>
      </ResumeBlock>

      <DetailsWrapper>
        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Boutique:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {order.shopAddress}
          </Text>
        </TextWrapper>

        <IconWrapper>
          <ArrowDownIcon />
        </IconWrapper>

        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Client:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {order.street}
          </Text>
        </TextWrapper>

        <IdsWrapper paddingTop={SPACING[500]}>
          <Text fontSize="md">
            <span style={{ color: COLORS.gray }}>Id initial : </span>{' '}
            {order.relatedOrderID}
          </Text>
          <Text fontSize="md">
            <span style={{ color: COLORS.gray }}>Id : </span> {order.id}
          </Text>
        </IdsWrapper>
      </DetailsWrapper>
    </RecapWrapper>
  );
};

export default FollowRecap;
