import axios from 'axios';

const { REACT_APP_CF_BASE_URL } = process.env;

//${REACT_APP_CF_BASE_URL}/onNewOrderRequest
const onOrderCreate = async ({
  relatedOrderID,
  shopID,
  clientData: { phone, address, name, email },
}) => {
  const DEV_CF_URL = `${REACT_APP_CF_BASE_URL}/onNewOrderRequest`;

  const urlencoded = new URLSearchParams();
  urlencoded.append('shopID', shopID);
  urlencoded.append('clientName', name);
  urlencoded.append('clientEmail', email);
  urlencoded.append('clientPhone', phone);
  urlencoded.append('clientAddress', address);
  urlencoded.append('relatedOrderID', relatedOrderID);

  const response = await axios({
    method: 'post',
    url: DEV_CF_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlencoded,
  });

  return response;
};

//${REACT_APP_CF_BASE_URL}/getOrder
const getOrder = async ({ orderID }) => {
  const DEV_CF_URL = `${REACT_APP_CF_BASE_URL}/getOrderRequest`;

  const response = await axios({
    method: 'get',
    url: `${DEV_CF_URL}?orderID=${orderID}`,
  });

  return response;
};

//${REACT_APP_CF_BASE_URL}/onNewOrderRequest
const onOrderUpdate = async ({ orderID }) => {
  const DEV_CF_URL = `${REACT_APP_CF_BASE_URL}/onUpdateOrderRequest`;

  const urlencoded = new URLSearchParams();
  urlencoded.append('orderID', orderID);

  const response = await axios({
    method: 'post',
    url: DEV_CF_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlencoded,
  });

  return response;
};

//${REACT_APP_CF_BASE_URL}/onStripeCheckoutRequest
const onStripeCheckout = async ({ orderID }) => {
  const DEV_CF_URL = `${REACT_APP_CF_BASE_URL}/onStripeCheckoutRequest`;

  const urlencoded = new URLSearchParams();
  urlencoded.append('orderID', orderID);

  const response = await axios({
    method: 'post',
    url: DEV_CF_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlencoded,
  });

  return response;
};

export { onOrderCreate, getOrder, onOrderUpdate, onStripeCheckout };
