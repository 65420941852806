import React from 'react';
import styled from 'styled-components';
import { Spinner, Text } from '@chakra-ui/react';

import { SPACING, COLORS } from '../../../constants';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  padding: ${SPACING[300]};
`;

const LoaderCalculator = props => {
  return (
    <LoaderWrapper>
      <Spinner size="xl" color={COLORS.primary} />
      <TextWrapper>
        <Text fontSize="xl">Veuillez patienter un instant ...</Text>
      </TextWrapper>
    </LoaderWrapper>
  );
};

export default LoaderCalculator;
