const COLORS = {
  primary: '#A0387F',
  yellow: '#ECC94B',
  red: '#F56565',
  blue: '#4299E1',
  black: '#1A202C',
  white: '#F7FAFC',
  gray: '#A0AEC0',
  blueDark: '#2D3748',
  darkTransparent: 'rgba(0,0,0,0.3)',
  blueBg: '#1D1751',
};

export default COLORS;
