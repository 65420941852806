import styled from 'styled-components';
import { RightArrowCircle } from '@styled-icons/boxicons-solid';
import { File } from '@styled-icons/boxicons-regular';
import { mediaQueries } from '../../helpers';
import { SPACING, COLORS, BOX_SHADOW } from '../../constants';

const CalculatorWrapper = styled.div`
  margin: auto;
  margin-top: ${props => props.marginTop || '-90px'};
  width: 85%;
  max-width: 1080px;
  padding: ${SPACING[300]} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px ${COLORS.gray};
  background-color: ${COLORS.white};
  box-shadow: ${BOX_SHADOW.lg};

  ${mediaQueries.tablet(`
    width: 98%;
    text-align: center;
  `)}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[300]} 0;
`;

const AddressIcon = styled(File)`
  color: ${COLORS.gray};
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
`;

const ArrowDownIcon = styled(RightArrowCircle)`
  color: ${COLORS.primary};
  width: 2em;
  height: 2em;
  display: inline-block;
  line-height: 1em;
`;

const ArrowDownWrapper = styled.div`
  padding: 0 ${SPACING[200]};

  ${mediaQueries.tablet(`
    padding: ${SPACING[200]};
    transform: rotate(90deg);
  `)}
`;

const DetailsWrapper = styled.div`
  max-width: 450px;
  text-align: left;
  padding-right: ${SPACING[200]};

  ${mediaQueries.laptop(`
    text-align: center;
    max-width: 350px;
  `)}

  ${mediaQueries.mobile(`
    padding: ${SPACING[200]};
  `)}
`;

const Illustration = styled.div`
  width: 600px;
  height: 250px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: ${SPACING[300]};
  margin-bottom: ${SPACING[200]};
  padding: 0 ${SPACING[200]};

  ${mediaQueries.laptop(`
    width: 300px;
    margin-right: ${SPACING[200]};
  `)}

  ${mediaQueries.tablet(`
    display: none;
  `)}
`;

const IllustrationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${SPACING[300]} 0;

  ${mediaQueries.laptop(`
    padding: ${SPACING[200]} 0;
    justify-content: center;
  `)}

  ${mediaQueries.tablet(`
    flex-direction: column;
    justify-content: center;
  `)}
`;

const InputsWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  padding: ${SPACING[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  ${mediaQueries.tablet(`
    flex-direction: column;
    justify-content: center;
    padding: ${SPACING[300]}
  `)}

  ${mediaQueries.mobile(`
    flex-direction: column;
    justify-content: center;
    padding: ${SPACING[200]}
  `)}
`;

const PriceWrapper = styled.div`
  padding: ${SPACING[300]} 0;
`;

export {
  CalculatorWrapper,
  ButtonWrapper,
  AddressIcon,
  ArrowDownIcon,
  ArrowDownWrapper,
  DetailsWrapper,
  Illustration,
  IllustrationWrapper,
  InputsWrapper,
  PriceWrapper,
};
