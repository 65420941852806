import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Button } from '@chakra-ui/react';

import { COLORS, SPACING } from '../../../constants';
import {
  RecapWrapper,
  ResumeBlock,
  DetailsWrapper,
  IconWrapper,
  TextWrapper,
  IdsWrapper,
  ButtonWrapper,
} from '../../styled/Recap';

import { ArrowDownIcon } from '../../styled/Calculator';

const RecapView = props => {
  const { order } = props;
  const history = useHistory();

  const onClick = () => {
    history.push(`/checkout/${order.id}`);
  };

  return (
    <RecapWrapper>
      <IdsWrapper>
        <Text fontSize="md">
          <span style={{ color: COLORS.gray }}>Id initial : </span>{' '}
          {order.relatedOrderID}
        </Text>
      </IdsWrapper>

      <ResumeBlock>
        <Text fontSize="lg" color={COLORS.gray} paddingX={SPACING[300]}>
          {order.duration}
        </Text>
        <Text fontSize="2xl" color={COLORS.primary} paddingX={SPACING[300]}>
          {order.cost}€
        </Text>
        <Text fontSize="lg" color={COLORS.gray} paddingX={SPACING[300]}>
          {order.distance}
        </Text>
      </ResumeBlock>

      <DetailsWrapper>
        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Boutique:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {order.shopAddress}
          </Text>
        </TextWrapper>

        <IconWrapper>
          <ArrowDownIcon />
        </IconWrapper>

        <TextWrapper>
          <Text fontSize="md" color={COLORS.gray}>
            Client:{' '}
          </Text>
          <Text fontSize="lg" as="em" color={COLORS.black}>
            {order.street}
          </Text>
        </TextWrapper>
      </DetailsWrapper>

      <ButtonWrapper>
        <Button
          size="lg"
          colorScheme="pink"
          variant="solid"
          minWidth="30%"
          onClick={onClick}
        >
          Passer au paiement
        </Button>
      </ButtonWrapper>
    </RecapWrapper>
  );
};

export default RecapView;
