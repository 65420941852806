import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Heading } from '@chakra-ui/react';

import { COLORS } from '../../constants';
import {
  DetailsWrapper,
  Illustration,
  IllustrationWrapper,
  PriceWrapper,
  CalculatorWrapper,
} from '../styled/Calculator';
import IllustrationHome from '../../assets/illus-home.svg';
import PageSkeleton from './PageSkeleton';
import HomeForm from './childs/HomeForm';

const HomePage = props => {
  const [orderID, setOrderID] = React.useState(null);
  const history = useHistory();

  const onSubmit = () => {
    history.push(`/recap/${orderID}`);
  };

  return (
    <PageSkeleton>
      <CalculatorWrapper>
        <Heading as="h2" size="lg" color={COLORS.primary}>
          illiCorsica Paiement
        </Heading>

        <IllustrationWrapper>
          <Illustration src={IllustrationHome} />

          <DetailsWrapper>
            <Text fontSize="md">
              IlliCorsica Paiement est le service vous permettant de payer et
              suivre vos commandes livré par illiCorsica.
            </Text>

            <PriceWrapper>
              <Text fontSize="xl">
                Nos tarifs:{' '}
                <span style={{ color: COLORS.primary }}>
                  5 euros / 15 min de trajet
                </span>
                <br />
                <span style={{ fontSize: '14px' }}>
                  {' '}
                  *10€ de frais pour tout trajet de plus de 45min
                </span>
              </Text>
            </PriceWrapper>

            <Text fontSize="md">
              Vous pouvez suivre votre commande directement, il vous suffit de
              rentrer l'id de votre commande ci-dessous
            </Text>
          </DetailsWrapper>
        </IllustrationWrapper>

        <HomeForm
          onSubmit={onSubmit}
          onChangeOrderID={setOrderID}
          canSubmit={!!orderID}
          orderID={orderID}
        />
      </CalculatorWrapper>
    </PageSkeleton>
  );
};

export default HomePage;
