import React from 'react';
import styled from 'styled-components';

import Header from '../Header';

import { SPACING } from '../../constants';

import '../../styles/App.css';

const MainContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  min-height: 70vh;
  margin: auto;
  padding: ${SPACING[300]} ${SPACING[100]};
`;

const PageSkeleton = props => {
  const { children } = props;

  return (
    <>
      <Header />
      <MainContainer>{children}</MainContainer>
    </>
  );
};

export default PageSkeleton;
