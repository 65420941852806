import React from 'react';
import styled from 'styled-components';
import { Heading } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { onStripeCheckout, onOrderUpdate, getOrder } from '../../actions';
import { CalculatorWrapper } from '../styled/Calculator';
import PageSkeleton from './PageSkeleton';
import LoaderCalculator from './childs/LoaderCalculator';
import { SPACING } from '../../constants';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const MessageWrapper = styled.div`
  padding: ${SPACING[300]} ${SPACING[100]};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = ({ message }) => (
  <MessageWrapper>
    <Heading as="h2" size="md">
      {message}
    </Heading>
  </MessageWrapper>
);

const Checkout = props => {
  const [inProgress, setInProgress] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const history = useHistory();
  const params = useParams();

  const onSuccesfulPayment = async orderID => {
    await onOrderUpdate({ orderID: params.orderID });
    setInProgress(false);
    history.push(`/order/${params.orderID}`);
  };

  const onCanceledPayment = orderID => {
    history.push(`/recap/${params.orderID}`);
  };

  /* eslint react-hooks/exhaustive-deps: 0 */
  React.useEffect(() => {
    const redirectToCheckout = async () => {
      const order = await getOrder({
        orderID: params.orderID,
      });

      if (order.data.order.isPaid !== 'FALSE') {
        history.push(`/order/${params.orderID}`);
        return;
      } else {
        const stripe = await stripePromise;
        const response = await onStripeCheckout({ orderID: params.orderID });
        const session = await response.data;
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      }
    };

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage(
        'Paiement accepté, vous allez recevoir un mail avec les details de la commande 🎉'
      );
      return onSuccesfulPayment();
    }
    if (query.get('canceled')) {
      return onCanceledPayment();
    }
    if (
      !inProgress &&
      !message &&
      !query.get('success') &&
      !query.get('canceled')
    ) {
      setInProgress(true);
      return redirectToCheckout();
    }
  }, []);

  return (
    <PageSkeleton>
      <CalculatorWrapper>
        {message ? <Message message={message} /> : <LoaderCalculator />}
      </CalculatorWrapper>
    </PageSkeleton>
  );
};

export default Checkout;
