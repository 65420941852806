import React from 'react';
import { InputGroup, InputLeftElement, Input, Button } from '@chakra-ui/react';
import {
  ButtonWrapper,
  AddressIcon,
  InputsWrapper,
} from '../../styled/Calculator';

const HomeForm = props => {
  const { onSubmit, onChangeOrderID, canSubmit, orderID } = props;

  return (
    <>
      <InputsWrapper>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            padding={'4px'}
            children={<AddressIcon />}
          />
          <Input
            type="text"
            placeholder="Identifiant de la commande"
            onChange={e => onChangeOrderID(e.target.value)}
            value={orderID || ''}
          />
        </InputGroup>
      </InputsWrapper>

      <ButtonWrapper>
        <Button
          size="lg"
          colorScheme="pink"
          variant="solid"
          minWidth="40%"
          onClick={onSubmit}
          isDisabled={!canSubmit}
        >
          Suivre ma commande
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default HomeForm;
