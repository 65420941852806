import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { onOrderCreate } from '../../actions';
import LoaderCalculator from './childs/LoaderCalculator';

import PageSkeleton from './PageSkeleton';

const parseQueryParams = search => {
  const cleanedSearch = search.replace(/\?/g, '');

  const result = JSON.parse(
    '{"' +
      cleanedSearch
        .replace(/\+/g, ' ')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
    function(key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );

  return result;
};

// http://localhost:8888/compute?shopID=ddjkjdkjkddd&clientName=alban+suchaire&clientEmail=test@test.fr&clientAddress=1265+promenade+marie+paradis+74400+chamonix&clientPhone=0601020304&orderID=older-than-payment
const ComputeCreateOrder = props => {
  const [loading, setLoading] = React.useState(false);
  let location = useLocation();
  const history = useHistory();
  const params = parseQueryParams(location.search);

  const redirectTo = pathname => {
    history.push(pathname);
  };

  /* eslint react-hooks/exhaustive-deps: 0 */
  React.useEffect(() => {
    async function createAndCompute() {
      const response = await onOrderCreate({
        relatedOrderID: params.orderID,
        shopID: params.shopID,
        clientData: {
          phone: params.clientPhone,
          address: params.clientAddress,
          name: params.clientName,
          email: params.clientEmail,
        },
      });

      if (
        response.data &&
        response.data.isExist &&
        response.data.isPaid !== 'FALSE'
      ) {
        return redirectTo(`/order/${response.data.id}`);
      }

      redirectTo(`/recap/${response.data.id}`);
      setLoading(false);
    }

    if (!!params && !loading) {
      setLoading(true);
      createAndCompute();
    }
  }, []);

  return (
    <PageSkeleton>
      {loading ? (
        <LoaderCalculator />
      ) : (
        <div>
          <h1>Une erreur s'est produite</h1>
          <p>
            Une erreur est survenu durant la création de la commande ou le
            calcul des frais, veuillez rééssayez
          </p>
        </div>
      )}
    </PageSkeleton>
  );
};

export default ComputeCreateOrder;
