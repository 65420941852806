import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import { Heading } from '@chakra-ui/react';

import { COLORS } from '../../constants';
import { CalculatorWrapper } from '../styled/Calculator';

import MainView from './childs/MainCalculatorView';
import RecapView from './childs/RecapCalculatorView';
import PageSkeleton from './PageSkeleton';

const ADMIN_ID = 'JDL2KOldk3456uidsjklsDk394JK03JHSkdlJDKEJ0d90';

const DeliveryCalculator = props => {
  const params = useParams();
  const [deliveryDetails, setDeliveryDetails] = React.useState({
    cost: 0,
    duration: null,
    distance: null,
    addresses: null,
  });

  if (!params.adminID || params.adminID !== ADMIN_ID) {
    return <Redirect to={'/'} />;
  }

  const isComputed = deliveryDetails.cost > 0 && !!deliveryDetails.duration;

  return (
    <LoadScript googleMapsApiKey="AIzaSyAdGC8QiL_RisC3WMQUVmdoQXnjqPN6Bws">
      <PageSkeleton>
        <CalculatorWrapper>
          <Heading as="h2" size="lg" color={COLORS.primary}>
            Calculateur cout de livraison
          </Heading>

          {isComputed ? (
            <RecapView deliveryDetails={deliveryDetails} />
          ) : (
            <MainView
              setComputed={setDeliveryDetails}
              deliveryDetails={deliveryDetails}
            />
          )}
        </CalculatorWrapper>
      </PageSkeleton>
    </LoadScript>
  );
};

export default DeliveryCalculator;
