import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Heading } from '@chakra-ui/react';

import { getOrder } from '../../actions';
import { COLORS } from '../../constants';
import { CalculatorWrapper } from '../styled/Calculator';
import RecapView from './childs/RecapView';

import PageSkeleton from './PageSkeleton';
import LoaderCalculator from './childs/LoaderCalculator';

const RecapBeforePayment = props => {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const history = useHistory();

  const redirectTo = pathname => {
    history.push(pathname);
  };

  /* eslint react-hooks/exhaustive-deps: 0 */
  React.useEffect(() => {
    async function readOrder() {
      const response = await getOrder({
        orderID: params.orderID,
      });

      if (response.data && !response.data.isExist) {
        setError('Aucune commande trouvé, verifier que le lien est correct.');
        setLoading(false);
        return;
      }

      if (response.data && response.data.order.isPaid !== 'FALSE') {
        redirectTo(`/order/${response.data.order.id}`);
        setLoading(false);
        return;
      }

      setOrder(response.data.order);
      setLoading(false);
    }

    if (!!params.orderID && !loading && !order && !error) {
      setLoading(true);
      readOrder();
    }
  }, []);

  return (
    <PageSkeleton>
      <CalculatorWrapper>
        <Heading as="h2" size="lg" color={COLORS.primary}>
          Récapitulatif de votre commande
        </Heading>

        {!!error ? <p>{error}</p> : null}

        {!!loading && !order && !error ? <LoaderCalculator /> : null}

        {!!order && !error ? <RecapView order={order} /> : null}
      </CalculatorWrapper>
    </PageSkeleton>
  );
};

export default RecapBeforePayment;
