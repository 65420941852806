import React from 'react';
import { Heading } from '@chakra-ui/react';

import { CalculatorWrapper } from '../styled/Calculator';
import PageSkeleton from './PageSkeleton';

const NoMatch = props => {
  return (
    <PageSkeleton>
      <CalculatorWrapper marginTop={'-50px'}>
        <Heading as="h2" size="md">
          Erreur 404, Aucune page ne correspond
        </Heading>
      </CalculatorWrapper>
    </PageSkeleton>
  );
};

export default NoMatch;
