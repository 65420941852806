import React from 'react';
import { DistanceMatrixService } from '@react-google-maps/api';

import CalculatorForm from './CalculatorForm';
import LoaderCalculator from './LoaderCalculator';

const DELIVERY_COST = 5;
const DELIVERY_COST_STEP = 15;

const MainCalculatorView = props => {
  const { setComputed } = props;

  const [error, setError] = React.useState(null);
  const [canCallGMaps, toggleCanCallGMaps] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [addresses, setAddresses] = React.useState({
    shopAddress: null,
    clientAddress: null,
  });

  const onChangeShopAddress = value => {
    setAddresses({ ...addresses, shopAddress: value });
  };

  const onChangeClientAddress = value => {
    setAddresses({ ...addresses, clientAddress: value });
  };

  const onSubmit = () => {
    setLoading(true);
    toggleCanCallGMaps(true);
    setError(null);
  };

  const distanceCallback = response => {
    if (canCallGMaps) {
      toggleCanCallGMaps(false);

      if (response.rows[0].elements[0].status === 'NOT_FOUND') {
        setError({
          status: response.rows[0].elements[0].status,
          message:
            'Les adresses que vous avez choisis sont invalides. Corrigez et réessayez 🙃 ',
        });

        return;
      }

      if (response.rows[0].elements[0].status === 'OK') {
        const result = {
          destination: response.destinationAddresses[0],
          origin: response.originAddresses[0],
          distance: response.rows[0].elements[0].distance,
          duration: response.rows[0].elements[0].duration,
          status: response.rows[0].elements[0].status,
        };

        onComputeDeliveryFees(result);
      }
    }

    return;
  };

  const onComputeDeliveryFees = async data => {
    const duration = Math.floor(data.duration.value / 60);
    const unitDelivery = duration / DELIVERY_COST_STEP;
    const cost =
      unitDelivery < 1 ? DELIVERY_COST : unitDelivery * DELIVERY_COST;
    const finalCost = duration > 45 ? cost + 10 : cost;

    setComputed({
      cost: Math.ceil(finalCost),
      duration: data.duration.text,
      distance: data.distance.text,
      addresses: { clientAddress: data.destination, shopAddress: data.origin },
    });
    setLoading(false);
  };

  const canSubmit =
    !!addresses.shopAddress &&
    !!addresses.clientAddress &&
    addresses.clientAddress !== addresses.shopAddress;

  return (
    <>
      {isLoading && !error ? (
        <>
          <LoaderCalculator />

          {canCallGMaps ? (
            <DistanceMatrixService
              options={{
                destinations: [addresses.clientAddress],
                origins: [addresses.shopAddress],
                travelMode: 'DRIVING',
              }}
              callback={distanceCallback}
            />
          ) : null}
        </>
      ) : (
        <CalculatorForm
          onChangeShopAddress={onChangeShopAddress}
          onChangeClientAddress={onChangeClientAddress}
          onSubmit={onSubmit}
          canSubmit={canSubmit}
          error={error}
          addresses={addresses}
        />
      )}
    </>
  );
};

export default MainCalculatorView;
