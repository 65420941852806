import React from 'react';
import styled from 'styled-components';

import logo from '../assets/logo.png';
import { COLORS, SPACING } from '../constants';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[100] / 2};
  border-radius: 4px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray};
`;

const Logo = props => {
  return (
    <a href="/">
      <LogoWrapper>
        <img src={logo} className="App-logo" alt="logo" />
      </LogoWrapper>
    </a>
  );
};

export default Logo;
